<template>
  <section id="features" class="features">
    <div class="container">
      <div class="features-header">
        <h2>Powerful Features</h2>
        <p>Everything you need to streamline your analytics workflow</p>
      </div>

      <div class="features-grid">
        <div 
          v-for="feature in features" 
          :key="feature.id"
          class="feature-tile"
          v-scroll-animation
        >
          <div class="feature-icon">
            <i :class="feature.icon" :style="{ color: feature.iconColor }"></i>
          </div>
          <div class="feature-content">
            <h3>{{ feature.title }}</h3>
            <p>{{ feature.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { scrollAnimation } from '@/directives/scrollAnimation'

export default {
  name: 'FeaturesComponent',
  directives: {
    'scroll-animation': scrollAnimation
  },
  data() {
    return {
      features: [
        {
          id: 1,
          title: 'AI-Powered Knowledge Assistant',
          description: 'Reduce time spent on repetitive data requests by 70% with an AI that instantly answers stakeholder questions using your team\'s past analyses and documentation. Free your analysts to focus on high-impact projects.',
          icon: 'fas fa-robot fa-4x',
          iconColor: '#4A7A9D'
        },
        {
          id: 2,
          title: 'Centralized Analytics Hub',
          description: 'End the chaos of scattered analyses across tools and drives. Create a searchable repository of your team\'s insights, complete with version history, rich previews, and powerful search capabilities.',
          icon: 'fas fa-database fa-4x',
          iconColor: '#A3D5E0'
        },
        {
          id: 3,
          title: 'Seamless Tool Integration',
          description: 'Import analyses directly from Jupyter, R Studio, and PowerPoint. Our platform automatically creates beautiful, stakeholder-friendly renderings while preserving technical details for your team.',
          icon: 'fas fa-plug fa-4x',
          iconColor: '#F8C2C9'
        },
        {
          id: 4,
          title: 'Analytics Request Management',
          description: 'Transform how your team handles stakeholder requests. Prioritize effectively, track team capacity, and demonstrate impact with comprehensive delivery metrics and stakeholder satisfaction tracking.',
          icon: 'fas fa-tasks fa-4x',
          iconColor: '#A3D5E0'
        }
      ]
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Open+Sans&display=swap');

.features {
  padding: 100px 0;
  background: #fafafa;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
}

.features-header {
  text-align: center;
  margin-bottom: 60px;
}

.features-header h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #2c3e50;
}

.features-header p {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  padding: 20px 0;
}

.feature-tile {
  background: white;
  border-radius: 8px;
  padding: 30px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.feature-icon {
  flex-shrink: 0;
}

.feature-icon i {
  font-size: 2rem;
}

h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #2c3e50;
}

p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .features {
    padding: 60px 0;
  }

  .features-header h2 {
    font-size: 2rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.scroll-animate {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease-out;
}

.scroll-animate-active {
  opacity: 1;
  transform: translateY(0);
}
</style>