<template>
  <div class="page-container">
    <main class="main-content">
      <div class="content-sections">
        <h3 v-scroll-animation class="section-title">Our Mission</h3>
        <p v-scroll-animation class="section-description">
          Ara is building the infrastructure that transforms how enterprises create, share, and leverage insights. 
          We're on a mission to help organizations build true analytical advantage by turning scattered insights into 
          compounding institutional knowledge.
        </p>

        <h3 v-scroll-animation class="section-title">The Challenge</h3>
        <p v-scroll-animation class="section-description">
          Today's enterprises face a paradox: despite heavy investments in data infrastructure and talent, they 
          struggle to fully leverage their analytical capabilities. Valuable insights remain trapped in private 
          notebooks, forgotten dashboards, and buried Slack threads, while data teams are caught in cycles of 
          repetitive work.
        </p>
        
        <p v-scroll-animation class="section-description">
          As organizations scale their data teams, these problems compound – making it increasingly difficult to 
          build upon existing work or maintain analytical consistency. The result is duplicate work, inconsistent 
          analyses, and critical decisions made on outdated information.
        </p>

        <h3 v-scroll-animation class="section-title">Why We Started Ara</h3>
        <p v-scroll-animation class="story-text">
          Our founders encountered this challenge while working at Meta and Google. Despite having 
          world-class talent and tools, they observed that growing data teams often made decision-making slower, 
          not faster. The problem wasn't data access or technical capabilities – it was that organizations lacked 
          the infrastructure to turn individual analyses into institutional knowledge.
        </p>

        <blockquote v-scroll-animation class="highlight-quote">
          The most valuable analytics aren't just technically sound – they're discoverable, reusable, and build 
          upon the collective intelligence of your entire data team.
        </blockquote>

        <h3 v-scroll-animation class="section-title">Our Solution</h3>
        <p v-scroll-animation class="story-text">
          We're building an intelligent collaboration platform that helps enterprises unlock the full potential of 
          their data teams. Our platform combines powerful discovery capabilities with collaborative workflows that 
          make it easy to find, reuse, and build upon existing analyses – turning isolated insights into 
          organizational knowledge.
        </p>

        <p v-scroll-animation class="story-text">
          We're at the beginning of this journey, working closely with leading organizations to shape the future 
          of enterprise analytics collaboration. If you're interested in transforming how your organization leverages 
          its analytical capabilities, we'd love to connect.
        </p>
      </div>
    </main>
  </div>
</template>

<script>
import { scrollAnimation } from '@/directives/scrollAnimation'

export default {
  name: 'LandingPage',
  directives: {
    'scroll-animation': scrollAnimation
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@400;600&family=Inter:wght@400;500;600&display=swap');

.font-serif {
  font-family: 'Crimson Text', serif;
}

.page-container {
  min-height: 100vh;
  padding-bottom: 32px;
  background-color: white;
}

.main-content {
  max-width: 1024px;
  margin: 0 auto;
  padding: 5px 96px;
}

.content-sections {
  margin: 5px 0;
  font-family: 'Crimson Text', serif;
}

.content-section {
  margin-top: 0;
  margin-bottom: 128px;
}

.section-title {
  font-size: 2rem;
  margin: 1.5rem 0 0.5rem;
  font-family: 'Crimson Pro', serif;
  font-weight: 600;
}

.section-description,
.story-text {
  font-size: 1rem;
  line-height: 1.75;
  color: #1f2937;
  font-family: 'Inter', sans-serif;
  margin-bottom: 1.5rem;
}

.story-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.highlight-quote {
  font-family: 'Crimson Pro', serif;
  font-size: 1.25rem;
  line-height: 1.6;
  color: #1f2937;
  font-style: italic;
  position: relative;
  padding: 2rem 3rem;
  margin: 2rem 0;
  border-left: 6px solid;
  border-image: linear-gradient(to bottom, #4A7A9D, #A3D5E0, #F8C2C9) 1;
  background-color: #f8f9fa;
}

.highlight-quote::before {
  content: '"';
  font-size: 4rem;
  position: absolute;
  top: -1rem;
  left: 0.5rem;
  opacity: 0.2;
}

@media (max-width: 768px) {
  .main-content {
    padding: 5px 24px;
  }

  .section-title {
    font-size: 1.75rem;
  }

  .section-description,
  .story-text {
    font-size: 0.875rem;
  }

  .highlight-quote {
    font-size: 1.125rem;
    padding: 1.5rem 2rem;
  }
}

/* Animation classes */
.scroll-animate {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s ease-out;
}

.scroll-animate-active {
  opacity: 1;
  transform: translateY(0);
}

.story-text+.story-text {
  transition-delay: 0.2s;
}

.highlight-quote {
  transition-delay: 0.3s;
}

.section-description+.section-description {
  transition-delay: 0.2s;
}

.bullet-list {
  list-style-type: disc;
  padding-left: 48px;
}
</style>